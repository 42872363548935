import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Drawer, IconButton, Stack } from '@mui/material';
import Clear from '@mui/icons-material/Clear';
import { useHistory } from 'react-router-dom';

import AutocompleteInput from '@fingo/lib/components/inputs/AutocompleteInput';
import { getFiltersFromHistory, clearFiltersFromHistory } from '@fingo/lib/helpers/url-filter-translator';
import {
  CREDIT_NOTE_STATES_OPTIONS,
  DOCUMENT_TYPE_OPTIONS,
  FINANCING_STATES_OPTIONS,
  INITIAL_INVOICE_STATES,
  INTEGRATION_STATES_OPTIONS,
  LOAN_STATES_OPTIONS,
  SII_STATES_OPTIONS,
} from '../../constants/invoices';

const StatesFilter = ({ open, onClose }) => {
  const history = useHistory();
  const defaultFilters = getFiltersFromHistory(history);

  const [invoiceStates, setInvoiceStates] = useState(defaultFilters);

  const clearInvoiceStates = () => {
    setInvoiceStates(INITIAL_INVOICE_STATES);
    clearFiltersFromHistory(history);
    onClose(INITIAL_INVOICE_STATES);
  };

  const onChangeHandler = (newValue, property) => {
    if (Array.isArray(newValue)) {
      setInvoiceStates({ ...invoiceStates, [property]: newValue.map((item) => item.value) });
      return;
    }
    setInvoiceStates({ ...invoiceStates, [property]: newValue.value || newValue });
  };

  return (
    <Drawer anchor="right" open={open} onClose={() => onClose(invoiceStates)}>
      <Stack spacing={2} style={{ maxWidth: '500px', width: '100vw', padding: '20px' }}>
        <Stack direction="row" justifyContent="space-between">
          <IconButton onClick={() => onClose(invoiceStates)}>
            <Clear />
          </IconButton>
          <Button variant="link" onClick={clearInvoiceStates} id="clear-filters">
            Limpiar filtros
          </Button>
        </Stack>
        <AutocompleteInput
          options={FINANCING_STATES_OPTIONS}
          value={invoiceStates.availableForFinancing}
          label="Estado de financiamiento"
          onChange={(newValue) => onChangeHandler(newValue, 'availableForFinancing')}
        />
        <AutocompleteInput
          options={LOAN_STATES_OPTIONS}
          value={invoiceStates.loanedStatus}
          label="Estado de cesión"
          onChange={(newValue) => onChangeHandler(newValue, 'loanedStatus')}
        />
        <AutocompleteInput
          options={SII_STATES_OPTIONS}
          value={invoiceStates.siiStatus}
          label="Estado SII"
          onChange={(newValue) => onChangeHandler(newValue, 'siiStatus')}
          multiple
        />
        <AutocompleteInput
          options={CREDIT_NOTE_STATES_OPTIONS}
          value={invoiceStates.creditNoteStatus}
          label="Estado nota de crédito"
          onChange={(newValue) => onChangeHandler(newValue, 'creditNoteStatus')}
          multiple
        />
        <AutocompleteInput
          options={DOCUMENT_TYPE_OPTIONS}
          value={invoiceStates.dteType_Code_In}
          label="Tipo de documento"
          onChange={(newValue) => onChangeHandler(newValue, 'dteType_Code_In')}
          multiple
        />
        <AutocompleteInput
          options={INTEGRATION_STATES_OPTIONS}
          value={invoiceStates.documentFinanceStatus}
          label="Estado de integraciones"
          onChange={(newValue) => onChangeHandler(newValue, 'documentFinanceStatus')}
          multiple
        />
      </Stack>
      <Button
        sx={{ alignSelf: 'start', ml: 4, mt: 2 }}
        variant="contained"
        size="small"
        onClick={() => onClose(invoiceStates)}
        id="apply-filters"
      >
        Aplicar
      </Button>
    </Drawer>
  );
};

StatesFilter.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};
StatesFilter.defaultProps = {
  open: false,
};

export default StatesFilter;
