import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, Typography, Stack } from '@mui/material';
import { SelectBankAccount } from '@fingo/lib/components/selects';
import { PasswordInput } from '@fingo/lib/components/inputs';

const OfferInternalCession = ({
  bankId,
  setBankId,
  certificatePassword,
  setCertificatePassword,
  error,
}) => (
  <Stack>
    <SelectBankAccount setBankId={setBankId} bankId={bankId} />
    <FormControl fullWidth margin="dense">
      <Typography variant="h2">Ingresa la clave de tu certificado digital</Typography>
      <PasswordInput
        fullWidth
        value={certificatePassword}
        onChange={(event) => setCertificatePassword(event.target.value)}
        sx={{ mt: 1 }}
      />
    </FormControl>
    {Boolean(error) && (
    <Typography
      variant="caption"
      component="div"
      color="error"
      align="center"
      gutterBottom
    >
      {error}
    </Typography>
    )}
  </Stack>
);

OfferInternalCession.propTypes = {
  bankId: PropTypes.number.isRequired,
  setBankId: PropTypes.func.isRequired,
  certificatePassword: PropTypes.string.isRequired,
  setCertificatePassword: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};

export default OfferInternalCession;
