import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import ProgressiveImageBox from '@fingo/lib/components/boxes/ProgressiveImageBox';
import { PasswordInput, ValidationTextFieldInput } from '@fingo/lib/components/inputs';
import { UPDATE_INVOICE_PROVIDER_CREDENTIALS } from '@fingo/lib/graphql';
import { useInputRut, useIsMobile, useSelectedCompany } from '@fingo/lib/hooks';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import UploadDigitalCertificateForm from '../upload-digital-certificate-dialog/UploadDigitalCertificateForm';

const CredentialProviderAuthForm = ({
  authenticationType,
  selectedProvider,
  onCancelHandler,
  showGoBack,
}) => {
  const { id, iconUrl, integrated, name, requiresEmail } = selectedProvider;
  const company = useSelectedCompany();
  const isMobile = useIsMobile();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const { nationalIdentifier: rut, updateNationalIdentifier: updateRut } = useInputRut();

  const [uploadCredentials, { loading }] = useMutation(UPDATE_INVOICE_PROVIDER_CREDENTIALS, {
    variables: {
      companyId: company.id,
      secondUserName: email,
      password,
      naturalPersonRut: rut.raw,
      credentialTypeId: id,
    },
  });

  const onChangeInput = useCallback(
    (event) => {
      const handlers = {
        rut: updateRut,
        password: setPassword,
        email: setEmail,
      };
      const { name: eventName, value } = event.target;
      handlers[eventName](value);
    },
    [updateRut],
  );

  return (
    <Stack spacing={3} alignItems="center" mx={isMobile ? 0 : 8}>
      <ProgressiveImageBox src={iconUrl} alt={name} />
      {!integrated && (
        <Typography color="error" align="center">
          {name} no está integrado en Fingo. Sin embargo puedes subir tus credenciales de igual
          forma para que así lo podamos integrar más adelante.
        </Typography>
      )}
      {!showGoBack && (
        <Button
          variant="text"
          size="small"
          onClick={onCancelHandler}
          id="back-to-invoice-credentials"
          sx={{
            width: { xs: '100%', md: 'auto' },
            textDecoration: 'underline',
          }}
        >
          ¿No es tu facturador? {isMobile && <br />} Selecciona aquí el tuyo
        </Button>
      )}
      {authenticationType === 'certificate' && (
        <UploadDigitalCertificateForm credentialProviderName={selectedProvider.name} />
      )}
      {authenticationType === 'username' && (
        <>
          <ValidationTextFieldInput
            name="rut"
            type="text"
            validationtype="rut"
            label={requiresEmail ? 'Email' : 'Rut usuario'}
            variant="standard"
            fullWidth
            value={rut.formatted}
            onChange={onChangeInput}
          />
          {requiresEmail && (
            <ValidationTextFieldInput
              name="email"
              type="text"
              validationtype="email"
              label="Correo electrónico"
              variant="standard"
              fullWidth
              value={email}
              onChange={onChangeInput}
            />
          )}
          <PasswordInput
            name="password"
            fullWidth
            label="Contraseña"
            variant="standard"
            value={password}
            onChange={onChangeInput}
          />
          <Stack
            direction={isMobile ? 'column' : 'row-reverse'}
            spacing={1}
            justifyContent="center"
            sx={{
              width: '100%',
            }}
          >
            <LoadingButton
              variant="contained"
              color="primary"
              size="small"
              type="submit"
              loading={loading}
              id="save-invoice-credentials"
              sx={{ width: { xs: '100%', md: 'auto' } }}
              onClick={uploadCredentials}
            >
              Guardar credenciales
            </LoadingButton>
            {showGoBack && (
              <Button
                variant={isMobile ? 'outlined' : 'text'}
                size="small"
                onClick={onCancelHandler}
                id="back-to-invoice-credentials"
                sx={{ width: { xs: '100%', md: 'auto' } }}
              >
                Atrás
              </Button>
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
};

CredentialProviderAuthForm.propTypes = {
  selectedProvider: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    iconUrl: PropTypes.string,
    integrated: PropTypes.bool.isRequired,
    requiresEmail: PropTypes.bool.isRequired,
    credentialProviderType: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  onCancelHandler: PropTypes.func.isRequired,
  authenticationType: PropTypes.string.isRequired,
  showGoBack: PropTypes.bool,
};

CredentialProviderAuthForm.defaultProps = {
  showGoBack: false,
};

export default CredentialProviderAuthForm;
