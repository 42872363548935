import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useIsMobile, useGetUser } from '@fingo/lib/hooks';
import { Box, Button, Stack } from '@mui/material';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { BankAccountForm, BankAccountCard } from './bank-accounts-dialog';
import {
  BANK_ACCOUNTS_DIALOG_ICON,
  BANK_ACCOUNTS_DIALOG_SUBTITLE,
  BANK_ACCOUNTS_DIALOG_TITLE,
} from '../../constants/credentials';

const BankAccountsDialog = ({ open, setOpen }) => {
  const [openBankAccountForm, setOpenBankAccountForm] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState();
  const user = useGetUser();
  const isMobile = useIsMobile();

  const bankAccounts = useMemo(
    () => user?.selectedCompany?.bankAccounts?.filter((a) => a?.active) || [],
    [user],
  );

  return (
    <FingoDialog
      open={open}
      handleClose={() => {
        setSelectedBankAccount(null);
        setOpenBankAccountForm(false);
        setOpen(false);
      }}
      maxWidth="sm"
      fullWidth
      title={BANK_ACCOUNTS_DIALOG_TITLE}
      subtitle={!isMobile ? BANK_ACCOUNTS_DIALOG_SUBTITLE : null}
      icon={!isMobile ? BANK_ACCOUNTS_DIALOG_ICON : null}
    >
      {openBankAccountForm ? (
        <BankAccountForm
          bankAccounts={bankAccounts}
          selectedBankAccount={selectedBankAccount}
          onClose={(newBankAccount) => {
            setSelectedBankAccount(newBankAccount);
            setOpenBankAccountForm(false);
          }}
        />
      ) : (
        <Box
          sx={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '95%',
          }}
        >
          <Stack spacing={2} sx={{ py: 2, alignItems: 'center' }}>
            {bankAccounts.map((bankAccount) => (
              <BankAccountCard
                key={bankAccount.id}
                bankAccount={bankAccount}
                selectedBankAccount={selectedBankAccount}
                onSelect={(newSelectedBankAccount) => {
                  setSelectedBankAccount(newSelectedBankAccount);
                }}
                onEditHandler={() => setOpenBankAccountForm(true)}
                selectable
              />
            ))}
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setSelectedBankAccount(null);
                setOpenBankAccountForm(true);
              }}
              fullWidth
              sx={{ width: '50%' }}
            >
              {bankAccounts?.length > 0 ? 'Agregar otra cuenta' : 'Agregar una cuenta'}
            </Button>
          </Stack>
        </Box>
      )}
    </FingoDialog>
  );
};

BankAccountsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default BankAccountsDialog;
