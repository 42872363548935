const STATUS_TO_SPANISH = {
  SIMULATION: 'Simulación',
  CREATED: 'Creada',
  EVALUATION: 'En evaluación',
  REJECTED: 'Rechazada',
  OFFERED: 'Aceptada',
  PROCESSING: 'Procesando cesión',
  PENDINGASSIGNMENTDOCUMENT: 'Pendiente de mandato',
  PENDINGSIGNATURE: 'Pendiente de mandato',
  PENDINGTRANSFER: 'Pendiente de giro',
  PENDINGCOLLECTION: 'En recaudación',
  PENDINGINVOICES: 'Pendiente de factura',
  PROCESSINGINVOICES: 'Procesando Cesión',
  FACTORING: 'En factoring',
  FINISHED: 'Finalizada',
};

export default STATUS_TO_SPANISH;
