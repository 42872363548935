import React from 'react';
import PropTypes from 'prop-types';
import { Link, Stack } from '@mui/material';
import ItemsGrid from '@fingo/lib/components/grids/ItemsGrid';
import SelectableImageCard from '@fingo/lib/components/cards/SelectableImageCard';
import DropdownMenu from '@fingo/lib/components/menus/DropdownMenu';
import { CREDENTIAL_PROVIDER_HELP_URL } from '@fingo/lib/constants';
import { useIsMobile } from '@fingo/lib/hooks';

const CredentialProviderSelector = ({
  integratedProviders,
  notIntegratedProviders,
  currentProvider,
  onProviderClickHandler,
  showHelpLink,
  buttonLabel,
  listSubheaderLabel,
}) => {
  const isMobile = useIsMobile();
  return (
    <Stack direction="column" alignItems="center" spacing={2}>
      <ItemsGrid
        items={integratedProviders}
        renderItem={(provider) => (
          <SelectableImageCard
            item={{
              id: provider.id,
              imageUrl: provider.iconUrl,
              label: provider.name,
            }}
            onClick={() => onProviderClickHandler(provider)}
            isSelected={currentProvider?.id === provider.id}
          />
        )}
      />
      <DropdownMenu
        items={notIntegratedProviders}
        onItemClick={onProviderClickHandler}
        buttonLabel={buttonLabel}
        listSubheaderLabel={listSubheaderLabel}
      />
      {showHelpLink && (
        <Link
          href={CREDENTIAL_PROVIDER_HELP_URL}
          target="_blank"
          underline={isMobile ? 'always' : 'hover'}
          sx={{
            mt: 3,
            fontSize: '0.9rem',
            textAlign: 'center',
          }}
        >
          ¿Por qué es necesario vincular la cuenta del facturador?
        </Link>
      )}
    </Stack>
  );
};

CredentialProviderSelector.propTypes = {
  integratedProviders: PropTypes.arrayOf(
    PropTypes.shape({
      iconUrl: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  notIntegratedProviders: PropTypes.arrayOf(
    PropTypes.shape({
      iconUrl: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onProviderClickHandler: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  currentProvider: PropTypes.string,
  showHelpLink: PropTypes.bool,
  listSubheaderLabel: PropTypes.string,
};

CredentialProviderSelector.defaultProps = {
  currentProvider: null,
  showHelpLink: false,
  listSubheaderLabel: 'Otro',
};

export default CredentialProviderSelector;
