import React from 'react';
import { Stack, Typography } from '@mui/material';
import {
  successFirstParagraph,
  successSecondParagraph,
  successThirdParagraph,
} from '../../../constants/preoffers-shopping-cart';

const PreofferSuccessMessage = () => (
  <Stack direction="column" px={7} pb={3}>
    <Typography variant="h5" marginTop={3}>
      {successFirstParagraph}
    </Typography>
    <Typography variant="h5" marginTop={3}>
      {successSecondParagraph}
    </Typography>
    <Typography variant="h5" marginTop={3}>
      {successThirdParagraph}
    </Typography>
  </Stack>
);

export default PreofferSuccessMessage;
