import { ExternalOfferShoppingCart } from '@fingo/lib/components/shopping-cart';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const OfferExternalCession = ({ bankId, setBankId }) => (
  <Grid container sx={{ padding: 5 }}>
    <ExternalOfferShoppingCart bankId={bankId} setBankId={setBankId} />
  </Grid>
);

OfferExternalCession.propTypes = {
  bankId: PropTypes.number.isRequired,
  setBankId: PropTypes.func.isRequired,
};

export default OfferExternalCession;
