import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Typography } from '@mui/material';
import FilterButton from '@fingo/lib/components/buttons/FilterButton';
import StatesFilter from './StatesFilter';

const InvoiceStatesFilter = ({ onSubmit }) => {
  const [openInvoiceStatesFilterDialog, setOpenInvoiceStatesFilterDialog] = useState(false);

  const onClickHandler = () => {
    setOpenInvoiceStatesFilterDialog(true);
  };

  const onCloseHandler = (newFilters) => {
    onSubmit(newFilters);
    setOpenInvoiceStatesFilterDialog(false);
  };

  return (
    <>
      <Badge badgeContent={1} color="primary">
        <FilterButton
          sx={{
            backgroundColor: 'white',
            borderColor: 'primary',
          }}
          variant="containedReverse"
          onClick={onClickHandler}
          id="filters"
        >
          <Typography variant="filter">Filtros</Typography>
        </FilterButton>
      </Badge>
      {openInvoiceStatesFilterDialog && (
        <StatesFilter open={openInvoiceStatesFilterDialog} onClose={onCloseHandler} />
      )}
    </>
  );
};

InvoiceStatesFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default InvoiceStatesFilter;
