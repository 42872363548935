import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import OfferExternalCession from './OfferExternalCession';
import OfferInternalCession from './OfferInternalCession';

const OfferSelectBankAndCertificatePassword = ({
  isExternal,
  bankId,
  setBankId,
  certificatePassword,
  setCertificatePassword,
  error,
}) => (
  <Grid container>
    { isExternal ? (
      <OfferExternalCession
        bankId={bankId}
        setBankId={setBankId}
      />
    ) : (
      <OfferInternalCession
        bankId={bankId}
        setBankId={setBankId}
        certificatePassword={certificatePassword}
        setCertificatePassword={setCertificatePassword}
        error={error}
      />
    )}
  </Grid>
);

OfferSelectBankAndCertificatePassword.propTypes = {
  isExternal: PropTypes.bool.isRequired,
  bankId: PropTypes.number.isRequired,
  setBankId: PropTypes.func.isRequired,
  certificatePassword: PropTypes.string.isRequired,
  setCertificatePassword: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
};

export default OfferSelectBankAndCertificatePassword;
