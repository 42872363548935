import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography, Stack, Tooltip } from '@mui/material';
import Circle from '@mui/icons-material/Circle';
import useDisableInternalCession from '../../../hooks/useDisableInternalCession';

const OfferSelectCessionType = ({ isExternalCession, setIsExternalCession }) => {
  const { disableFingoCession, disabledFingoCessionText } = useDisableInternalCession();
  useEffect(() => {
    setIsExternalCession(disableFingoCession);
  }, [disableFingoCession]);
  return (
    <Stack spacing={2} px={5} pb={3}>
      <Typography variant="h2" align="center" sx={{ width: '100%' }}>
        ¿Como quieres ceder tu(s) factura?
      </Typography>
      <FormControl
        fullWidth
        margin="dense"
        sx={{ mt: 2 }}
      >
        <RadioGroup
          value={isExternalCession}
          name="cession-options"
          onChange={(_, value) => setIsExternalCession(value)}
        >
          <Tooltip
            title={disabledFingoCessionText}
            placement="left"
            arrow
            size="small"
            disableHoverListener={!disableFingoCession}
          >
            <FormControlLabel
              value={false}
              control={<Radio checkedIcon={<Circle />} />}
              label="Quiero que Fingo lo haga por mi"
              disabled={disableFingoCession}
            />
          </Tooltip>
          <FormControlLabel
            value
            control={<Radio checkedIcon={<Circle />} />}
            label="Prefiero hacerlo yo, manualmente, desde mi facturador"
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

OfferSelectCessionType.propTypes = {
  isExternalCession: PropTypes.bool.isRequired,
  setIsExternalCession: PropTypes.func.isRequired,
};

export default OfferSelectCessionType;
